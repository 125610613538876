.content {
    flex: 1; /* Make content take up remaining space */
}

/* the whole footer <Box component="footer"> */
footer {
    /* absolute in relative to body and root */
    position: absolute;
    top: 100%;
    background-color: black;
    padding: 20px 10px;
    width: 100%;
    flex-shrink: 0; /* Ensure footer doesn't grow or shrink */
}

#footer-muibox {
    flex: 1; /* Push footer to the bottom by taking up remaining space */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

footer #footer-container {
    display: flex;
    justify-content: space-between;
    margin: 0 15%;
}  

footer p {
    text-align: left;
    margin-bottom: 0;
    color: white;
}

footer #social-media {
    font-size: 24px;
    margin-top: 15px;
}

@media (max-width: 768px) {
    footer #footer-container {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
      margin: 0;
    }
  
    #footer #logo {
      width: 30px;
      height: 30px;
    }
  
    footer #footer-container #box {
      margin: 5px 0 5px;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    footer #footer-container {
      margin: 0;
      gap: 5px;
    }
  }
  
  @media only screen and (min-width: 1024px) and (max-width: 1439px) {
    footer #footer-container {
      margin: 0 0%;
      gap: 5px;
    }
}