#home-container {
    position: relative; /* Ensure positioning context for absolute positioning */
}

#top-panel {
    position: relative;
    height: 70vh; /* Set height to full viewport height */
    overflow: hidden; /* Prevent overflow */
}

#home-container #background-img {
    width: 100%;
    height: 100%;
    position: relative; /* Position image absolutely */
    top: 0;
    left: 0;
    z-index: -1; /* Move image behind other content */
}

.search-box-container {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
}

.search-heading {
    margin-bottom: 10px; /* Add space between the headings */
    color: white;
}

#home-search-bar-box {
    width: 50vw;
    display: flex; /* Use flexbox to align items */
    align-items: center; /* Center items vertically */
    z-index: 1; /* Ensure the search box appears below the heading */
}

#home-search-bar-box {
    margin: 0 auto;
}

#home-search-bar-box #home-search-bar {
    border-radius: 0px;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    padding: 15px;
    border-left: 0px;
    border-right: 0px;
    box-shadow: 1px 1px 10px rgba(210, 208, 208, 0.3);
}

#home-search-bar-box #home-search-btn {
    background-color: white;
    border-color: white;
    color: black;
    border-radius: 0px !important;
    width: 5%; /* 10% of search-bar-box div */
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    padding: 15px;
    border: 1px solid rgb(229, 228, 228);
    border-left: 0px;
    box-shadow: 1px 1px 10px rgba(210, 208, 208, 0.3);
}

#search-bar-box #search-btn:hover {
    background-color: rgb(229, 228, 228);
}

#ulsearch-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 100%;
    top: 100%; /* Position the ul below the search bar */
    left: 0; /* Ensure it's aligned with the left edge */
    z-index: 1; /* Ensure it's above other content */
}

#ulsearch-list li:hover,
#specs li:hover {
    background-color: rgb(238, 233, 233) !important;
    cursor: pointer;
}

#bottom-panel {
    /* margin-top: 5%; */
    height: 40vh;
    background-color: white;
}

#carousel-bigcontainer {
    margin-left: 13%;
    margin-right: 13%;
    /* display: flex; */
}

.container-car {
    width: 100%;
    display: flex;
    overflow-y: hidden;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 5%;
}

/* .container-car #next-btn, 
.container-car #prev-btn {
    border: none;
    box-shadow: 1px 1px 10px rgb(190, 189, 189);
    cursor: pointer;
    color: white;
    padding: 0px;
    transition: all .3s ease-in-out;
} */

.item-list {
    width: 100% !important;
    /* padding: 30px 0px; */
    display: flex;
    /* gap: 38px; */
    scroll-behavior: smooth;
    transition: all 0.25s ease-in;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: auto;
    scroll-snap-type: x mandatory;
    overflow-y: hidden;
}

.item-list .item-div {
    margin: 0 20px 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.item-list::-webkit-scrollbar {
  display: none;
}

.prev-btn, .next-btn {
    padding: 0;
    border-radius: 50%;
    background-color: black;
    color: white;
    cursor: pointer;
    justify-content: center;
    align-self: center;
}

#car-item-image {
    width: 100%; /* Make the image fill the entire width of its container */
    height: 100%; /* Maintain the image's aspect ratio */
    display: block; /* Ensure proper spacing and alignment */
}

#item-img {
    scroll-snap-align: center;
    min-width: 150px;
    max-width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover; /* Ensure images don't stretch */
}

.item-div .item-name {
    color: black;
    font-weight: 400 !important;
    font-size: 25px;
    margin: 0;
    text-align: center;
}

/* Adjust the bold text style */
.item p.bold-text {
    font-weight: bold;
}

@media (max-width: 768px) {
    #top-panel {
        height: 50vh;
    }
    #home-search-bar-box {
        width: 90vw;
        top: 70%;
    }
    #bottom-panel {
        margin-top: 5%;
    }
    #bottompan-h3 {
        margin-left: 0 !important;
        text-align: center;
    }
    .item-list {
        /* width: 67.5vw; */
        /* padding: 15px 0px !important; */
        gap: 20px !important;
    }
    .item-list .item-div {
        margin: 0 5px 0 !important;
    }
    #carousel-bigcontainer {
        margin-left: 10px;
        margin-right: 0;
    }
    #prev-btn, #next-btn {
        display: none;
    }
    #search-box-mobile {
        display: none !important;
    }
    .carousel-arrow {
        display: none !important;
    }
}