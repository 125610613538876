#showordertable {
    margin: 0 20%;
    margin-top: 2.5%;
}

#showordertable .cart-th {
    font-size: 25px;
    margin-left: 10px !important;
}

#showordertable td {
    padding: 15px;
}

#showordertable .productImg {
    width: 80px;
    height: 80px;
}

#showordertable .countInput {
    border: 0;
    width: 25px;
}

@media (max-width: 768px) {
    #showordertable {
        max-width: 100%;
        margin: 0 auto;
    }
    #showordertable .cart-th {
        font-size: 20px;
    }
    #showordertable td {
        padding: 5px;
    }
    #showordertable #orderstatus-h4 {
        margin-bottom: 10%;
    }
    #showordertable .productName {
        font-size: 16px;
    }
    #showordertable .productImg {
        width: 50px;
        height: 50px;
    }
    #showordertable .removeProductBtn{
        padding: 3;
        /* font */
    }
    #showordertable .checkoutBtn {
        padding: 3px;
    }
}