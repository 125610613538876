#table {
    margin: 0 10% !important;
    margin-bottom: 390px;
}

#table .cart-th {
    font-size: 25px;
    margin-left: 10px !important;
}

table tr {
    border-bottom: none;
}

table tr:last-child {
    border-bottom: 1px solid rgb(233, 232, 232);
}

table tr:not(:first-child) {
    border-top: none;
}  

#table td {
    padding: 10px !important;
    font-size: 15px !important;
}

#table .storeImg {
    width: 80px;
    height: 80px;
    border: 0.5px solid #e0dfdf;
    border-radius: 5px;
    box-shadow: 0.5px 0.5px 0.5px #c5c5c5;
}

#table .countInput {
    border: 0;
    width: 28px;
}

#table .iconBtn {
    font-size: 20px;
}

@media (max-width: 767px) {
    #table {
        max-width: 100%;
        margin: 0 auto !important;
    }
    #table .cart-th {
        font-size: 20px;
    }
    #table td {
        padding: 5px;
    }
    #table .productName {
        font-size: 16px;
    }
    #table .productImg {
        width: 50px;
        height: 50px;
    }
    #table .removeProductBtn{
        padding: 3;
        /* font */
    }
    #table #checkoutBtn {
        padding: 3px;
        margin-right: 5px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1366px){
    #table {
        margin: 0 5% !important;
    }    
}