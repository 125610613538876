#store-container {
    border: 0px !important;
    margin: 0 20% 20%;
    padding: 10px;
    margin-top: 2.5%;
}

#store-container #banner_div {
    height: 300px;
}

#store-container #store-image-banner {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Scale the image while preserving its aspect ratio to cover the entire container */
}

#store-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start; /* Align items at the top */
    margin-top: 2%;
}

#store-categories {
    width: 20%;
    align-self: flex-start; /* Align at the top */
    border: 0px !important;
}

#store-products {
    flex: 1;
    border-left: 1px solid rgb(220, 218, 218);
    padding-left: 20px;
}

#product {
    display: flex;
    align-items: stretch; /* Ensure all children stretch to the same height */
    margin-bottom: 10px;
    margin-top: 3%;
}

#product #product-image {
    width: 15%;
    height: 100%;
}

#product #product-image img {
    width: 100%; /* Ensure the image fills its container horizontally */
    height: 100%; /* Ensure the image fills its container vertically */
    object-fit: cover; /* Cover the entire area of the container */
}

#product #product-details {
    flex: 1; /* Expand to take up available space */
    margin-left: 10px;
}

#product #product-rating {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#store-ratings {
    margin-top: 2%;
}

#product #rating-stars{
    flex: 1;
}

@media (max-width: 768px) {
    #store-container {
        margin: 0;
        border: 0;
    }
    #store-menu {
        margin-top: 5%;
    }
    #store-categories {
        display: none;
    }
    #store-products {
        border-left: 0px;
        padding-left: 0px;
    }
    #product {
        margin-top: 5%;
    }
    #product #product-image {
        width: 25%;
    }
}