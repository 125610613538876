#signup-container {
    font-family: 'Roboto', sans-serif;
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 2.5%;
    place-items: center; /* Shorthand for align-items: center; justify-content: center; */
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translateX(-50%);
}

#signup-box {
    /* width: 300px; */
    border: 1px solid rgb(218, 218, 218);
    border-radius: 10px;
    padding: 50px;
    text-align: center;
}

.signup-option1 {
    margin-right: 15px !important;
}

@media (max-width: 768px) {
    #signup-container {
        width: 100%;
    }
    #signup-box {
        border: 0;
    }
    .signup-option1 {
        margin-bottom: 15px !important;
        margin-right: 0 !important;
        width: 100% !important;
    }
    .signup-option2 {
        width: 100% !important;
    }
}