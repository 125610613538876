body {
    height: 100vh;
}

#container {
    border: 1px solid rgb(188, 187, 187);
    margin: 0 15% 15%;
    padding: 10px;
}

#container #product-image-banner {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Scale the image while preserving its aspect ratio to cover the entire container */
}

#store-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#store-categories {
    border-right: 1px solid rgb(188, 187, 187);
    width: 20%;
    margin-right: 20px;
    padding-right: 5px;
}

#store-products {
    flex: 1;
}

#product {
    display: flex;
    align-items: stretch; /* Ensure all children stretch to the same height */
    margin-bottom: 10px;
}

#product #product-image {
    width: 15%;
    height: 100%;
}

#product #product-image img {
    width: 100%; /* Ensure the image fills its container horizontally */
    height: 100%; /* Ensure the image fills its container vertically */
    object-fit: cover; /* Cover the entire area of the container */
}

#product #product-details {
    flex: 1; /* Expand to take up available space */
    margin-left: 10px;
}

#product #product-rating {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#product #rating-stars{
    flex: 1;
}

#product #votes-nb {
    flex: 1;
}

@media (max-width: 768px) {
    #container {
        margin: 0;
        border: 0;
    }
    #store-categories {
        display: none;
    }
    #product {
        margin-top: 5%;
    }
    #product #product-image {
        width: 25%;
    }
}