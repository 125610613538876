#storetype-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 10px;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 2.5%;
}

@media (max-width: 768px) {
    #storetype-container {
        margin-left: 1%;
        margin-right: 1%;
        grid-template-columns: 1fr 1fr;
    }
}