body {
    direction: inherit; /* Default to the document's direction */
}

#search-bar-box {
    position: relative;
    width: 65%; /* 65% of navbar width */
    display: flex; /* Use flexbox to align items */
    align-items: center; /* Center items vertically */
    margin-right: 10px;
}

#search-bar-box #search-bar {
    border-radius: 0;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    width: 85%;  /* 85% of search-bar-box div */
    flex: 1; /* Allow input to grow and take remaining space */
    padding: 15px;
    border-right: 0px;
    box-shadow: 1px 1px 10px rgba(210, 208, 208, 0.3);
}

/* #search-bar-box #option,
#search-box-mobile #option2 {
    height: 100%;
    cursor: pointer;
    background-color: white;
    border-color: white;
    font-weight: bold;
    width: 10%; 20% of search-bar-box div */
    /* border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 15px;
    border: 1px solid rgb(229, 228, 228);
    border-right: 0px;
    box-shadow: 1px 1px 10px rgba(210, 208, 208, 0.3);
} */

#search-bar-box #search-btn {
    background-color: white;
    border-color: white;
    color: black;
    border-radius: 0px !important;
    width: 5%; /* 10% of search-bar-box div */
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    padding: 15px;
    border: 1px solid rgb(229, 228, 228);
    border-left: 0px;
    box-shadow: 1px 1px 10px rgba(210, 208, 208, 0.3);
}

#search-bar-box #search-btn:hover {
    background-color: rgb(229, 228, 228);
}

#ulsearch-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 100%;
    top: 100%; /* Position the ul below the search bar */
    left: 0; /* Ensure it's aligned with the left edge */
    z-index: 1; /* Ensure it's above other content */
}

#ulsearch-list li:hover,
#specs li:hover {
    background-color: rgb(238, 233, 233) !important;
    cursor: pointer;
}
/* MOBILE (and bigger) */

#search-box-mobile #search-bar {
    width: 350px !important;
    border-radius: 5px !important;
}

#search-bar-box button,
#search-box-mobile button {
    border-radius: 5px !important;
}

#search-bar-box button:hover {
    background-color: yellow !important;
    color: black !important;
}

#cart-wish-mobile {
    margin-right: 10px;
    margin-top: 25px;
    display: none;
    float: right;
}

#cart-wish-mobile a {
    color: black;
    text-decoration: none;
}

#cart-wish-mobile a:hover {
    background-color: yellow;
}

#location-mobile {
    display: none;
}

/* #specs li:hover {
    background-color: grey;
} */

@media (max-width: 767px) {
    #nav-toolbar {
        width: 100% !important;
    }
    #search-bar-box{
        display: none;
    }
    nav #horz-slash {
        display: none;
    }
    .container-fluid {
        margin: 0 !important;
    }
    .right-side {
        display: none;
    }
    #cart-wish {
        display: none;
    }
    #cart-wish-mobile {
        display: block;
        margin-bottom: 10px;
    }
    #addressSelect1, 
    #delivery-big {
        display: none;
    }
    #search-box,
    #search-box #search-bar,
    #search-box #option {
        display: none;
    }
    #search-box-mobile {
        display: block !important;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px;
    }
    #search-box-mobile #search-bar-mobile {
        width: 65% !important;
    }
    #search-box-mobile #option2 {
        margin: 0;
        border-radius: 0px !important;
        width: 25%;
    }
    #search-box-mobile button {
        width: 10% !important;
        border-radius: 0px !important;
    }
    #location-mobile {
        display: block;
        margin-left: 10px;
        margin-bottom: 10px;
    }
    #mobile-line {
        display: block !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1366px){
    #search-bar-box {
        width: 40%; /* 30% of navbar width */
    }
    
    #search-bar-box #search-bar {
        width: 75%;  /* 70% of search-bar-box div */
    }

    #search-bar-box #option,
    #search-box-mobile #option2 {
        width: 15%; /* 20% of search-bar-box div */
    }

    #search-bar-box #search-btn {
        width: 10%; /* 10% of search-bar-box div */
    }
}