#addaddress-h2 {
    margin-left: 15%; 
    text-decoration: underline; 
    text-decoration-color: gold; 
    margin-top: 30px;
}

#addresses-container {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    margin: 0 15%;
    grid-column-gap: 10px;
}

#addresses-container #address p{
    text-decoration: none !important;
}

#addresses-container #addaddress-box,
#addresses-container #address{
    padding: 10px;
    background-color: #edeef0;
    border-color: #edeef0 !important;
    color: black;
    height: 205px;
    text-align: left;
    margin-bottom: 10px;
    border: 0.5px solid rgb(210, 208, 208);
}

#addresses-container #addaddress-box {
    position: relative;
    text-align: center !important;
}

#addresses-container #addaddress-box #inner-box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

#addresses-container #addaddress-box:hover {
    transition: 0.25s;
    transform: scale(1.01);
    cursor: pointer;
}

#addresses-container #address .address-p {
    margin: 0;
    font-weight: 200;
    font-size: small;
    margin-bottom: 5px;
    margin-left: 10px;
}

#address-btns {
    margin-top: 15px;
}

#address-btns #addressedit-btn {
    background-color: black;
    color: white;
    border: 0;
    padding: 5px 15px; /* top/bottom 5px, left/right 15px */
    margin-left: 10px;
    margin-right: 10px;
    font-size: small;
}

#address-btns #addressdelete-btn {
    color: black;
    background-color: red;
    border: 0;
    padding: 5px 15px;
    font-size: small;
}

@media (max-width: 768px) {
    #addaddress-h2 {
        margin-left: 10px !important;
    }
    #addresses-container {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 200px;
    }
    #addresses-container #addaddress-box {
        width: 100%;
        margin-left: 0px;
    }
}

@media only screen and (min-width: 800px) and (max-width: 1024px) {
    h2 {
        margin-left: 10% !important;
    }
    #addresses-container {
       margin: 0 10%;
       grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}